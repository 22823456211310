<template>
  <div id="app">
    <div class="tab">
      <ul>
          <li 
          :class="index==n ? 'active' : ''" v-for="(fruite,index) in fruites" 
          :key="index"
          @click="change(index)"
          >{{fruite.name}}</li>
      </ul>

      <div :class="index==n ? 'current' : ''" v-for="(fruite,index) in fruites" :key="index">
        <img :src="fruite.src" :alt="fruite.name">
      </div>
      
    </div>
  </div>
</template>

<script>
    export default {
        name:"MyFruit",
        data(){
            return{
                n:0,
                fruites:[
                    {name:'apple',src:require('../assets/img/apple.png')},
                    {name:'orange',src:require('../assets/img/orange.png')},
                    {name:'lemon',src:require('../assets/img/lemon.png')}
                ]
            }
        },
        methods:{
            change(n){
                this.n = n
            }
        }
    }
</script>

<style>
    .tab ul {
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
    .tab ul li {
      box-sizing: border-box;
      padding: 0;
      float: left;
      width: 100px;
      height: 45px;
      line-height: 45px;
      list-style: none;
      text-align: center;
      border-top: 1px solid blue;
      border-right: 1px solid blue;
      cursor: pointer;
    }
    .tab ul li:first-child {
      border-left: 1px solid blue;
    }
    .tab ul li.active {
      background-color: orange;
    }
    .tab div {
      width: 500px;
      height: 300px;
      display: none;
      text-align: center;
      font-size: 30px;
      line-height: 300px;
      border: 1px solid blue;
      border-top: 0px;
    }
    .tab div.current {
      display: block;
    }
</style>